<template>
  <v-sheet class="mx-md-5 mx-2">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'AvailableUnits', params: {} }"
        active
      >
        Available Units
      </v-breadcrumbs-item>
    </v-breadcrumbs>

  <div class="d-flex">
    <product-select
      label="Choose a product"
      v-model="product"
      return-object
      @input="findInventories"
    />
    <download class="ml-2 ml-md-5" v-if="downloadUnits && downloadUnits.length > 0" v-model="downloadUnits" fileName="availableUnits"/>
  </div>

    <v-progress-linear class="my-1" indeterminate v-if="loading" />

    <v-sheet class="pa-2 mt-4" :elevation="2">
      <v-text-field
        v-if="units.length > 0"
        class="mx-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-range-slider
        v-if="dates && dates.length > 4 && units.length > 10"
        v-model="dateRange"
        :max="maxDate"
        :min="minDate"
        hide-details
        class="align-center mt-4"
        @change="filter"
      >
        <template v-slot:prepend>
          <div style="white-space: nowrap;" class="text-caption">{{dates[dateRange[0]] | formatDateYear}}</div>
        </template>
        <template v-slot:append>
          <div style="white-space: nowrap;" class="text-caption">{{dates[dateRange[1]-1] | formatDateYear}}</div>
        </template>
      </v-range-slider>

      <v-data-table
        class="my-8"
        :hide-default-footer="units.length < 1"
        :hide-default-header="units.length < 1"
        :items="filterUnits"
        :headers="headers"
        item-key="id"
        :search="search"
      >
        <template v-slot:item.checkbox="{ item }">
          <v-checkbox v-model="item.selected" @change="confirmStatus(item)" />
        </template>
        <template v-slot:item.stockDate="{ item }">
          {{item.stockDate | formatDateYear}}
        </template>
        <template v-slot:item.amountPerUnit="{ item }">
          {{ item.amountPerUnit | formatNumber }} {{ item.unitMeasure }}
        </template>
        <template v-slot:item.confirmed="{ item }">
          <span v-if="item.confirmed"
            >By {{ item.confirmedBy }} on
            {{ item.confirmedTime | formatDateTime }}</span
          >
        </template>
      </v-data-table>

      <div v-if="filterUnits && filterUnits.length < 1 && !product">
          <v-banner icon="mdi-alert-circle-outline" class="text-center" color="primary" dark rounded :elevation="2">Please choose a product first.</v-banner>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
const Download = () => import("@/components/common/Download.vue");

export default {
  data: () => {
    return {
      product: null,
      units: [],
      filterUnits: [],
      downloadUnits: [],
      headers: [
        { text: "", value: "checkbox", sortable: false },
        { text: "Date", value: "stockDate" },
        { text: "Code", value: "code" },
        { text: "Units", value: "unitShare" },
        { text: "Unit Weight", value: "amountPerUnit" },
        { text: "Last Confirmation", value: "confirmed" },
      ],
      loading: false,
      search: null,
      dates: [],
      dateRange: [],
      minDate: 0,
      maxDate: 0,
    };
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  components: {
    ProductSelect, Download,
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage", "addMessage"]),
    findInventories() {
      this.loading = true;
      if(!this.product || this.product.id == null){
        this.loading = false;
        return;
      }

      const params = {
        product_id: this.product.id,
      };
      this.$axios
        .get("/inventory/available-units", { params })
        .then((response) => {
          this.units = response.data;
          this.filterUnits = this.units;

          if(this.units.length > 0){
            this.downloadUnits = this.units.map((unit) => {
              return {date: unit.stockDate, code: unit.code, unit: unit.unitShare, unitWeight: unit.amountPerUnit, measureUnit: unit.unitMeasure}
            })
          }

          //parse date min/max
          const dates = new Set();
          this.units.forEach((u) => {
            var b = u.stockDate.split(/\D/);
            const date = `${b[0]}-${b[1]}-${b[2]}`
            dates.add(date);
          })


          this.dates = [...dates].sort();
          this.dateRange = [0, this.dates.length]
          this.minDate = 0;
          this.maxDate = this.dates.length;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.addErrorMessage("Error retrieving units; " + error);
        });
    },
    parseISOLocalDate(s){
      var b = s.split(/\D/);
      return new Date(b[0], b[1] - 1, b[2]);
    },
    confirmStatus(row) {
      if (row.selected) {
        const time = new Date().getTime();
        const user = this.currentUser.firstName;
        this.$set(row, "confirmed", true);
        this.$set(row, "confirmedTime", time);
        this.$set(row, "confirmedBy", user);
        console.log(`confirmed on ${time} by ${user}`);
      } else {
        this.$set(row, "confirmed", false);
      }
    },
    filter(){
      const minDate = new Date(this.dates[this.dateRange[0]]);
      const maxDate = new Date(this.dates[this.dateRange[1]-1]);
      console.log('filtering date: ', this.minDate)
      this.filterUnits = this.units.filter( u => {
        var b = u.stockDate.split(/\D/);
        const date = `${b[0]}-${b[1]}-${b[2]}`
        const stockDate = new Date(date);
        return stockDate >= minDate && stockDate <= maxDate
      })
    }
  },
};
</script>

<style></style>
